/*==================   Thumbnail    ======================*/
$overlay-bg: #000;
$overlay-bg-opacity: 0.5;
$overlay-icon: $fa-expand;
$overlay-icon-size: 30px;
$overlay-icon-color: #fff;
$scheme: $scheme-1;

.thumb {
  display: block;
  position: relative;
  overflow: hidden;
  border: 9px solid #f8f8f8;
  outline: 1px solid #e4e4e4;


  img {
  	width: 100%;
  	height: auto;
    @include transition(.5s all ease);
  }

  &_overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    @include transition(.3s all ease);
    color: $overlay-icon-color;
    background: transparent;

    &:before {
      content: $overlay-icon;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      font: 400 $overlay-icon-size $fa;
      line-height: $overlay-icon-size;
      margin-top: -($overlay-icon-size / 2);
      text-align: center;

      .lt-ie9 & {
        display: none;
      }
    }

  }

  &:hover {
    .thumb_overlay {
      opacity: 1;
      @include background($overlay-bg, $overlay-bg-opacity);

      .lt-ie9 &:before {
        display: block;
      }
    }

    img {
      @include transform(scale(1.2));
    }
  }
}
