@import "mixins";
@import "variables";
@import "animate.css";
// Fonts
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
@import url(//fonts.googleapis.com/css?family=Exo+2:400,700,900);
@import url(//fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700);
@import url(//fonts.googleapis.com/css?family=Pacifico:400,700);

$scheme: $scheme-1;
/*========================================================
                      Main Stylest
=========================================================*/
body {
  color: #242424;
  font: 400 16px/24px $default-font;
  -webkit-text-size-adjust: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-size: 41px;
  line-height: 41px;

}

h2 {
  font-size: 46px;
  line-height: 58px;
  font-family: $alt-font;
  font-weight: 900;
  text-transform: uppercase;
}

h3 {
  font-size: 36px;
  font-family: $alt-font;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}

h4 {
  font-size: 24px;
  line-height: 24px;
}

h5 {
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-family: $default-font;
  font-weight: bold;
}

h6 {
}

p, .marked-list, .list {
  font-family: $default-font;

  .fa {
    text-decoration: none;
  }
}

time {
  display: block;
}

a {
  font: inherit;
  color: inherit;
  text-decoration: none;
  @include transition(.5s);
  &:focus {
    outline: none;
  }
  &:active {
    background-color: transparent;
  }
}

a[href^="tel:"],
a[href^="callto:"] {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: 700;
}

.fa {
  font-size: 80px;
  line-height: 80px;
  display: block;
  text-align: center;
  color: #4F4E4E;

}

.fa + span {
  text-align: center;
  display: block;
}

[class*='fa-'] {
  &:before {
    font-weight: 400;
    font-family: $fa;
  }
}

.page {
  overflow: hidden;
  min-height: 500px;

  .lt-ie9 & {
    min-width: 1200px;
  }
}

/*=======================================================
                       Main Layout
=========================================================*/

/*=======================================================
                        Components
=========================================================*/
@import "forms";
@import "select2";
@import "snackbars";
@import "thumb";
@import "search";
@import "google-map";

/*==================     Brand     ======================*/
.brand {
  display: inline-block;
  &_name {
    color: map-get($scheme, tretiary);
    font-weight: 400;
    font-family: 'Pacifico', sans-serif;
  }

  &_slogan {
    color: #727272; text-align: right;
  }
}

/*==================  Backgrounds  ======================*/
.bg {
  &-primary {
    background: map-get($scheme, primary);
  }
  &_1 {
    background: #f8f8f8;
    h4 {
      color: map-get($scheme, tretiary);
      text-align: center;
      margin-top: 27px;
    }
  }
  &_2 {
    background: map-get($scheme, primary);
  }

}

/*==================     Icons     ======================*/
.fa-facebook,
.fa-twitter,
.fa-google-plus,
.fa-linkedin {
}

/*==================    Buttons    ======================*/
.btn {
  color: map-get($scheme, default);
  background: map-get($scheme, tretiary);
  padding: 12px 29px;
  font-family: $default-font;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 25px;
  border-bottom: 3px solid darken(map-get($scheme, tretiary), 10%);
  &:hover {
    background: darken(map-get($scheme, primary), 10%);
    border-bottom: 3px solid map-get($scheme, quinary);
  }

  &:active {
    background: darken(map-get($scheme, primary), 10%);
  }
  &_1 {
    color: map-get($scheme, default);
    background: map-get($scheme, tretiary);
    padding: 12px 19px;
    font-family: $default-font;
    font-size: 24px;
    line-height: 24px;
    margin-top: 8px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 3px solid darken(map-get($scheme, tretiary), 10%);
    @include transition(0.3s all ease);
    &:hover {
      background: map-get($scheme, quinary);
      border-bottom: 3px solid darken(map-get($scheme, primary), 10%);
    }

    &:active {
      background: darken(map-get($scheme, primary), 10%);
    }
  }
}

.rowwr {
  top: -55px;
  position: relative;
}

.btn_top_offset {
  padding-top: 30px;
}



.subpage_btn {
  text-transform: uppercase;
  color: map-get($scheme, primary);
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  font-family: $default-font;
  display: inline-block;
  &:hover {
    color: map-get($scheme, tretiary);
  }
}

.img_wr + .list {
  margin-top: 20px;
}

/*==================      Box      ======================*/
.box {
  @include clearfix();

  &_aside {
    float: left;
  }

  &_cnt {
  }

  &_cnt__no-flow {
    overflow: hidden;
  }
}

//==================    Imports    ======================
@import "_menu.scss";
@import "_navbar.scss";
@import "_stuck-menu.scss";
@import "_to-top.scss";

/*========================================================
                     HEADER  Section
=========================================================*/
header {
  padding: 26px 0;
  .brand {
    float: left;
  }

  .nav {
    float: left;
    margin-left: 135px;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    text-align: center;

    .brand, .nav {
      float: none;
    }
  }
}

/*========================================================
                     CONTENT  Section
=========================================================*/
main {
  display: block;
}

blockquote {
  text-align: center;

  img {
    border-radius: 50%;
    display: inline-block;
    & + p {
      text-align: left;
      margin-top: 45px;
    }
  }
  cite {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    text-align: right;
    margin-top: 23px;
    display: block;
  }
}
p {

  a {
    @include transition(.5s);
    &:hover {
      //color:map-get($scheme, quinary);
      text-decoration: underline;
    }
  }
}
h3 + * {
  margin-top: 70px;
}

.alignleft {
  text-align: left;
}

.privacy {
  &:hover {
    text-decoration: underline;
  }
}

dl {
  margin-top: 22px;
  padding-right: 20%;
}

dt {
  float: left;
}

dd {
  text-align: right;
  &:hover {
    color: map-get($scheme, primary);
  }
}

.flright {
  float: right;
}

.link_mod {
  a {
    color: map-get($scheme, tretiary);
    &:hover {
      text-decoration: underline;
      color: map-get($scheme, secondary);
    }
  }

  &_1 {
    text-align: left;
  }
}

.list {

  li {
    & + li {
      margin-top: 20px;
    }
    a {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      line-height: 18px;
      &:hover {
        color: map-get($scheme, quinary);
      }
    }
  }
}

a + p {
  margin-top: 15px;
}

h5 + .list {
  margin-top: 22px;
}

h5 + p {
  margin-top: 25px;
}

.no_offset {
  margin-top: 0;
}

.team {
  text-align: center;
  img {
    border-radius: 50%;
  }
  h5 {
    margin-top: 50px;
  }

}

.count-list {
  counter-reset: list;
  display: inline;

  li {
    position: relative;
    padding-left: 70px;
    h5 {
      color: map-get($scheme, tretiary);
      font-size: 24px;
      line-height: 30px;
      text-transform: none;
      font-weight: bold;
      font-family: $default-font;
      padding-right: 30%;
    }

    &:before {
      content: counter(list, decimal-leading-zero) '.';
      counter-increment: list;
      color: map-get($scheme, secondary);
      font-size: 36px;
      font-weight: 900;
      font-family: $alt-font;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
  p {
    padding-right: 20%;
  }
}

.text_offset {
  padding-left: 30px;
}

.top_offset {
  margin-top: 53px;
}

.img_wr {
  border: 9px solid map-get($scheme, quarternary);
  outline: 1px solid #e4e4e4;
}

.policy_page {

  h3 + * {
    margin-top: 0;
  }
  h5 {
    margin-top: 50px;
  }
  p {
    margin-top: 30px;
  }
}

/*==================      Well     ======================*/
.well {
  padding: 75px 0;
  &_1 {
    padding: 80px 0;
  }
}

/*========================================================
                     FOOTER  Styles
=========================================================*/
footer {
  h3 {
    color: map-get($scheme, default);
    text-align: left;
    & + * {
      margin-top: 40px;
    }
  }
  p {
    padding-right: 26%;
  }

  .social-list {
    li {
      margin-right: 30px;
      float: left;
      margin-top: 8px;
      a {
        font-size: 30px;
        line-height: 30px;
        font-family: $fa;
        color: map-get($scheme, default);

        &:hover {
          color: map-get($scheme, quinary);
        }
      }
    }
  }
}

//--------------------------------------------------------------
//                        Responsive
//--------------------------------------------------------------
//--------------------------------------------------------------
@media (min-width: 1200px) and (max-width: 1315px) {
  .count-list {
    li {
      &:before {
        left: -3px;
      }
    }
  }
  .policy_page {
    padding: 0 5%;
  }

  dd {
    padding-right: 10px;
  }
}

@media (max-width: 1199px) {
}

//                        992 - 1199
@media (min-width: 992px) and (max-width: 1199px) {
  .btn_top_offset {
    padding-top: 25px;
  }

  .row + .row {
    margin-top: 40px;
  }
  .rowwr {
    top: -80px;
    position: relative;
  }
  .top_offset {
    margin-top: 0;
  }
  header .nav {
    margin-left: 25px;
  }
  .sf-menu > li + li {
    margin-left: 20px;
  }
.search-form {
  margin: 0;
  &_label {
    margin: 0;
  }

}
}


//                       768 -  991
@media (min-width: 768px) and (max-width: 991px) {

  h1 {
    font-size: 35px;
    line-height: 37px;
  }

  footer .social-list li {
    margin-right: 23px;
  }
  .rowwr {
    top: -67px;
  }
  .btn_top_offset {
    padding-top: 25px;
  }
  .count-list {
    li {
      padding-left: 0;
      &:before {
        position: static;
        display: block;
      }
    }
  }
  .nomargin_767 {
    margin-top: 0;
  }
  h5 {
    margin-top: 20px;
  }
  .row + .row {
    margin-top: 35px;
  }
  header {
    padding: 15px 0;

    .nav {
      margin-left: 15px;
    }
    .search-form {
      margin-top: 8px;
    }
  }
  .row + .row {
    margin-top: 20px;
  }
  .btn_top_offset_1 {
    padding-top: 8px;
  }
  .count-list {
    li {
      &:before {
        margin-bottom: 10px;
      }
    }
  }
}

//                       <  767
@media (max-width: 767px) {
  .search-form {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  blockquote {
    margin-top: 60px;
  }
  .list {
    margin-top: 17px;
  }

  .col-sm-3 + .col-sm-3 {
    margin-top: 70px;
  }

  .center__767 {
    text-align: center;
  }
  .top__767 {
    margin-top: 80px;
  }
  .alignleft {
    text-align: center;
  }
  h5 {
    margin-top: 30px;
  }
  footer h3 {
    margin-top: 40px;
    .list {
      text-align: left;
    }

  }

  .rowwr {
    top: 0;
    position: static;
    text-align: center;
  }
  .flright {
    float: none;
  }
  .social-list {
    margin-top: 80px;
    display: inline-block;
  }
  .copy {
    margin-top: 55px;
  }
  .team {
    p {
      margin-bottom: 80px;
    }
  }
  .count-list li::before {
    left: 15px; top: 0;
  }
  .policy_page {
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media (max-width: 512px) {
  footer {
    text-align: center;
    p {
      padding-right: 0;
      text-align: left;
    }
    .list {
      text-align: left;
      margin-top: 40px;
    }

  }
  h3 + * {
    margin-top: 45px;
  }
  .social-list {
    margin-top: 10px;
  }
  .fa {
    margin-top: 80px;
  }
  .bg_1 h4 {
    margin-top: 15px;
  }
  .team {
    h5 {
      margin-top: 20px;
    }
  }
  .count-list li::before {
    left: 15px;
    font-size: 30px;
  }
  .list {
    margin-top: 15px;
  }
  .social-list {
    margin-top: -50px;
  }

  .mfControls {
    margin-left: auto;
    margin-right: auto;
  }

}

//                        <479

