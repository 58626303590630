/**
 * Select 2
 */

// Size variables
$select2-height:             50px;
$select2-border-width:       0;
$select2-border-radius:      0;
$select2-font-size:          18px;
$select2-line-height:        30px;
$select2-padding-horizontal: 25px;
$select2-arrow:              true;
$select2-padding-vertical: ( $select2-height - $select2-line-height - $select2-border-width * 2 ) / 2;


// Appearance Variables
$select2-color:              $input-color;
$select2-bg:                 $input-bg;
$select2-font-family:        $default-font;
$select2-font-weight:        $input-font-weight;
$select2-arrow-color:        $input-color;
$select2-dropdown-color:     $input-color;
$select2-dropdown-bg:        $input-bg;
$select2-selected-color:     $select2-dropdown-bg;
$select2-selected-bg:        $select2-dropdown-color;
$select2-hover-color:        $select2-dropdown-bg;
$select2-hover-bg:           map_get( $scheme-1, 'tretiary' );
$select2-disabled-color:     #aaa;



// Functions
@function get-select2-padding() {
	@if ( $select2-arrow == true ) {
		@return $select2-padding-vertical ($select2-height - $select2-border-width) $select2-padding-vertical $select2-padding-horizontal;
	} @else {
		@return $select2-padding-vertical $select2-padding-horizontal;
	}
}


// Base Styles
.select2 {
	width: 100% !important;

	&:focus, * {
		outline: none;
	}

	&.select2-container--open .select2-selection__arrow {
		b { transform: rotate(180deg); }
	}
}

* + .select2 { margin-top: 7px; }

.select2-container {
	display: block;
	position: relative;
}

.select2-selection {
	overflow: hidden;
	text-overflow: ellipsis;
}

.select2-selection__rendered {
	white-space: nowrap;
}

.select2-selection__clear {
	position: relative;
}

.select2-selection--single,
.select2-selection--multiple {
	height: $select2-height;
	padding: get-select2-padding();
	font-size: $select2-font-size;
	line-height: $select2-line-height;
	border-width: $select2-border-width;
	border-radius: $select2-border-radius;

	font-family: $select2-font-family;
	font-weight: $select2-font-weight;
	background-color: $select2-bg;
	color: $select2-color;
}

.select2-selection--single {
	display: block;
	cursor: pointer;
	user-select: none;
}

.select2-selection__arrow {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: $select2-height;
	height: $select2-height;
	font-family: $fa;
	font-size: 18px;
	vertical-align: middle;
	line-height: 1;
	text-align: center;
	color: $select2-arrow-color;

	b { transition: .25s; }
	b:before { content: '\f107'; }
}

.select2-selection--multiple {
	display: block;
	cursor: pointer;
	user-select: none;
}

.select2-search--inline {
	float: left;
}

.select2-search__field {
	border: none;
	margin-top: 5px;
	padding: 0;

	&::-webkit-search-cancel-button { -webkit-appearance: none; }
}

.select2-dropdown {
	position: absolute;
	z-index: 12;
	display: block;
	width: 100%;
	padding: $select2-padding-vertical 0;
	border-style: solid;
	border-width: $select2-border-width;
	border-radius: $select2-border-radius;
	font-size: $select2-font-size;
	font-weight: $select2-font-weight;
	overflow: hidden;
	background-color: $select2-dropdown-bg;
	color: $select2-dropdown-color;
}

.select2-results {
	display: block;
}

.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.select2-results__option {
	padding: 2px $select2-padding-horizontal;
	transition: .3s;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;

	&[aria-disabled='true'] {
		color: $select2-disabled-color;
	}

	&[aria-selected] {
		cursor: pointer;
	}

	&[aria-selected='true'] {
		background: $select2-selected-bg;
		color: $select2-selected-color;
	}

	&:not([aria-disabled='true']):hover {
		background: $select2-hover-bg;
		color: $select2-hover-color;
	}
}

.select2-container--open {
	.select2-dropdown--above { margin-bottom: 5px; }
	.select2-dropdown--below { margin-top: 5px; }
}

.select2-search--dropdown {
	display: block;

	&.select2-search--hide {
		display: none;
	}
}

.select2-search__field {
	width: 100%;
	padding: 2px $select2-padding-horizontal;
	&::-webkit-search-cancel-button { -webkit-appearance: none; }
}

.select2-close-mask {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99;
	display: block;
	border: 0;
	margin: 0;
	padding: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	background-color: $input-bg;
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.select2 + .form-validation {
	z-index: 14;
}
