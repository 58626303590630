/*==================     ToTop     ======================*/
.toTop {
  width: 70px;
  height: 70px;
  font-size: 30px;
  line-height: 70px;
  color: #fff;
  background: map-get($scheme, tretiary);
  border-radius: 0%;
  border: 1px solid map-get($scheme, tretiary);
 // @include box-shadow(4px 4px 8px 0 rgba(#000, .4));

  position: fixed; 
  right: 23px;
  bottom: 40px;

  display: none;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 23;

  &:hover {
    color: #fff;
    background: map-get($scheme, primary);
    text-decoration: none;
    border: 1px solid map-get($scheme, treiary);
  }
}

.mobile .toTop, 
.tablet .toTop{
	display: none!important; 
}


