//========================================================
//                     Variables
//=========================================================

$scheme-1: (
        primary: #6fc45b,
        secondary: #242424,
        tretiary: #C64E4E,
        quarternary: #F8F8F8,
        default: #FFF,
        quinary: #3c7f2b
);

$default-font: 'PT Sans Narrow', sans-serif;
$alt-font: 'Exo 2', sans-serif;
