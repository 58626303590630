//
// Form styles
// --------------------------------------------------

$font-size-base:           18px;
$line-height-base:         1.2;

$input-color:              #727272;
$input-color-placeholder:  $input-color;
$input-bg:                 #fff;
$error-color:              #d9534f;
$success-color:            #5cb85c;
$input-font-family:        $default-font;
$input-font-weight:        400;

$screen-xs-min:            480px;
$screen-sm-min:            768px;
$screen-md-min:            992px;


.rd-mailform {
	position: relative;
	z-index: 1;
	text-align: left;

	* + .form-wrap { margin-top: 10px; }
	* + .button-wrap { margin-top: 10px; }

	@media (min-width: $screen-md-min) {
		* + .form-wrap { margin-top: 30px; }
		* + .button-wrap { margin-top: 30px; }
	}
}

form {
	@include form-input-sizing;

	.form-wrap {
		position: relative;
	}

	.form-input {
		display: block;
		width: 100%;
		font-family: $input-font-family;
		font-weight: $input-font-weight;
		color: $input-color;
		background-color: $input-bg;
		background-image: none;
		border-style: solid;
		-webkit-appearance: none;
		transition: .3s ease-in-out;

		&:focus { outline: 0; }
	}

	textarea.form-input {
		height: 139px;
		min-height: 50px;
		max-height: 250px;
		resize: vertical;
	}

	.form-label {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9;
		margin: 0;
		font-family: $input-font-family;
		font-weight: $input-font-weight;
		pointer-events: none;
		transition: .3s;
		color: $input-color-placeholder;

		&.focus { opacity: 0; }
		&.auto-fill { color: $input-color; }
	}

	.form-validation {
		position: absolute;
		top: 2px;
		z-index: 11;
		text-align: right;
		font-size: 11px;
		line-height: 11px;
		transition: .3s;
		color: $error-color;
	}

	.validation-with-outside-label {
		.form-validation {
			top: $font-size-base * $line-height-base + 7px;
		}
	}
}

#form-output-global {
	position: fixed;
	bottom: 30px;
	left: 15px;
	transform: translateX(-500px);
	z-index: 9999999;
	font-size: 14px;
	transition: .3s all ease;
	visibility: hidden;

	&.active {
		transform: translateX(0);
		visibility: visible;
	}

	@media (min-width: $screen-xs-min) {
		left: 30px;
	}
}

.form-output {
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 14px;
	line-height: 1.5;
	margin-top: 2px;
	transition: .3s;
	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	&.error {
		color: $error-color;
	}

	&.success {
		color: $success-color;
	}
}


.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
	opacity: 0;

	&, &-dummy {
		position: absolute;
		top: 4px;
		left: 0;
		width: 16px;
		height: 16px;
		outline: none;
		cursor: pointer;
	}

	&-dummy {
		pointer-events: none;
		background: $input-color;

		&:after {
			position: absolute;
			opacity: 0;
		}
	}

	&:focus {
		outline: none;
	}
}

.radio-inline,
.checkbox-inline {
	padding-left: 25px;
	font-weight: 400;

	.heading {
		font-weight: 400;
		text-transform: uppercase;
	}
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
	opacity: 1;
}


.radio,
.radio-inline {
	input[type="radio"] {
		margin: auto;
	}

	.radio-custom-dummy {
		border-radius: 50%;

		&:after {
			content: '';
			top: 4px;
			bottom: 4px;
			left: 4px;
			right: 4px;
			background: $input-bg;
			border-radius: 50%;
		}
	}
}


.checkbox,
.checkbox-inline {
	input[type="checkbox"] {
		margin: auto;
	}

	.checkbox-custom-dummy {
		pointer-events: none;
		border-radius: 3px;
		margin-left: 0;
		left: 0;

		&:after {
			content: '\e5ca';
			font-family: 'Material Icons';
			font-size: 20px;
			line-height: 10px;
			position: absolute;
			top: 0;
			left: 0;
			color: $input-color;
		}
	}
}


.inline-form {
	display: inline-flex;
	flex-wrap: wrap;
	width: 100%;

	.button-wrap,
	.form-wrap {
		flex-basis: 100%;
		flex-grow: 1;
	}

	.button {
		width: 100%;
	}

	* + .button-wrap {
		margin-top: 10px;
	}

	@media (min-width: $screen-xs-min) {
		flex-wrap: nowrap;

		.form-wrap {
			flex-shrink: 1;
			flex-grow: 0;
		}

		.button-wrap {
			flex-basis: 136px;
			flex-shrink: 0;
			flex-grow: 0;
			margin-top: 0;
			margin-left: 10px;

			.ie-11 &,
			.ie-edge & {
				min-width: 180px;
			}
		}
	}
}


.recaptcha {
	transform-origin: 0 50%;
	transform: scale(.8);

	@media (min-width: $screen-xs-min) {
		transform: none;
	}
}

.recaptcha-validation {
	.form-validation {
		left: 15px;
		right: 15px;
		top: 10px;
		text-align: left;

		@media (min-width: $screen-xs-min) {
			top: 2px;
		}
	}
}


// Offsets
* + .rd-mailform { margin-top: 30px; }
p + .inline-form { margin-top: 10px; }

@media (min-width: $screen-md-min) {
	* + .inline-form { margin-top: 47px; }
}
