/*
*
* Snackbars
* --------------------------------------------------
*/

$snackbar-bg: #333;
$snackbar-clr: #fff;

.snackbars {
  max-width: 280px;
  padding: 9px 16px;
  margin-left: auto;
  margin-right: auto;
  color: $snackbar-clr;
  text-align: left;
  background-color: $snackbar-bg;
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  
  p {
    display: block;
    line-height: 1;
  }
  
  span {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    vertical-align: top;
    color: $snackbar-clr;
  }

  span + span {
    margin-left: 10px;
  }

  @media (min-width: $screen-xs-min) {
    max-width: 380px;
    padding: 12px 15px;
    font-size: 16px;
  }
}
