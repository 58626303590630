@import "_mixins.scss";
@import "_variables.scss";
$scheme: $scheme-1;

//--------------------------------------------------------------
//                      Search Form
//--------------------------------------------------------------
$form-bg: #f3f3f3;
$form-width: 235px;
$form-height: 50px;
$form-border: 0px solid #acacac;
$form-color: #333;
$form-submit-icon: $fa-search;


.search-form {
  display: block;
  position: relative;
  width: $form-width;
  float: right;
  padding-top: 4px;

  &_label {
    display: block;
    padding: 13px 40px 13px 10px;
    background: $form-bg;
    border: $form-border;
  }

  &_input {
    @include reset-input;
    color: #727272;
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 24px;

    font-family: $default-font;
  }

  &_submit {
    @include transition(.3s all ease);
    position: absolute;
    top: 50%;
    @include transform(translate(0,-50%));
    right: 10px;
    width: 25px;
    height: 22px;
    color: #727272;
    font: 400 18px/22px 'FontAwesome', sans-serif;

    &:before {
      content: $form-submit-icon;
    }

    &:hover {
      color: map-get($scheme, primary);
    }
  }

  @media (max-width: 479px) {
    width: 270px;
  }
}

//--------------------------------------------------------------
//                     Search Toggle
//--------------------------------------------------------------
.search-form_toggle {
  float: right;
  display: inline-block;
  color: map-get($scheme, default);
  font: 400 44px/44px $fa;

  &:before {
    content: $fa-search;
  }

  &.active, &:hover {
    color: map-get($scheme, primary);
  }

  &.active {
    &:before {
      content: $fa-close;
    }
  }
}

// .search-form {
//   display: none;
// }

// .search-form.on{
//   display: block;
// }

//--------------------------------------------------------------
//                     Search Liveout
//--------------------------------------------------------------
.search-form_liveout {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 123;
  @include opacity(0);
  @include transition(.3s all ease);

  .search_list {
    font-size: 14px;
    line-height: 24px;
    background: #FFF;
    @include box-shadow(0 0 2px 0 rgba(0,0,0,.5));

    li + li {
      margin-top: 2px;
    }
  }

  .search_link {
    display: block;
    padding: 5px 10px;
    background: #FFF;
    color: #999;

    .search_title {
      color: map-get($scheme, primary);
      text-transform: none;
      font-weight: normal;
    }

    &:hover {
      background: map-get($scheme, primary);
      color: lighten(map-get($scheme, primary), 40%);

      .search_title {
        color: #FFF;
      }
    }
  }

  .search_error {
    display: block;
    color: map-get($scheme, primary);
    background: darken(#FFF, 2%);
    padding: 10px 10px;
    overflow: hidden;
    font-weight: normal;
  }

  .match {
    display: none;
  }

  button {
    display: block;
    width: 100%;
    color: map-get($scheme, primary);
    background: darken(#FFF, 2%);
    padding: 10px 10px;

    &:hover {
      background: map-get($scheme, primary);
      color: #FFF;
    }
  }

  input:focus + & {
    @include opacity(1);
  }

  .tablet, .mobile & {
    display: none !important;
  }
}

//--------------------------------------------------------------
//                    Search Results Page
//--------------------------------------------------------------
h4+ #search-results{
  margin-top: 30px;
}
.search-frame {
  &, body {
    width: 100%;
    height: auto;
    background: transparent;
    overflow: hidden;
  }

  &, body, h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: top;
    font-family: $alt-font;
  }
p, em {
  font-family: $default-font;
}
  img {
    max-width: 100%;
    height: auto;
  }

  a {
    font: inherit;
    color: inherit;
    text-decoration: none;

    &:active {
      background: transparent;
    }

    &:focus {
      outline: none;
    }

    @include transition(.3s all ease);
  }

  h4 {
    a:hover {
      color: map-get($scheme, primary);
    }
  }

  .search_list {
//    margin-top: 20px;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: top;
    list-style-type: none;
    counter-reset: li;


    li {
      padding-left: 30px;
      position: relative;
      counter-increment: li;
      &:before {
        content: counter(li, decimal) ".";

        position: absolute;
        left: 0px;
        top: -2px;
        font-size: 22px;
      }
      h4 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
      }

      & + li {
        margin-top: 20px;
      }
    }

  }

  .search {
    color: #777777;
  }

  .match {
    color: map-get($scheme, secondary);
    font-size: 11px;
    display: block;
  }

  * + p{
    margin-top: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .search-form {
    margin-right: 6%;
  }
  .search-form_label {
    margin-top: 12px;
  }
  .search-form_submit {
    top: 27px;
  }
}


