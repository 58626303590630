/*========================   Menu   ==========================*/

//--------------------------------------------------------------
//                       Menu Styles
//--------------------------------------------------------------
.sf-menu {
  @include clearfix;
  display: inline-block;


  > li {
    position: relative;
    float: left;

    & + li {
      margin-left: 40px;
    }
  }

  a {
    display: block;
  }
}

//--------------------------------------------------------------
//                     Menu item styles
//--------------------------------------------------------------
.sf-menu {

  font-family: $default-font;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  > li {
    > a {
      text-transform: uppercase;
      color: map_get($scheme, secondary);
    }

    &.sfHover > a, > a:hover {
      color: map-get($scheme, primary);
    }

    &.active > a {
      color: map-get($scheme, primary);

    }
  }
}

//--------------------------------------------------------------
//                   Sub menu lvl1 styles
//--------------------------------------------------------------
.sf-menu ul {
  display: none;
  position: absolute;
  top: 100%;
  width: 205px;
  margin-top: 40px;
  background: #FFF;
  z-index: 2;
  @include box-sizing(border-box);
  border: 1px solid map-get($scheme, quarternary);
}

//--------------------------------------------------------------
//                 Sub menu lvl1 item styles
//--------------------------------------------------------------



.sf-menu ul {
  left: -17%;
  > li {
    > a {
      color: map_get($scheme, secondary);
      line-height: 45px;
      padding-left: 20px;
    }

    &.sfHover > a, > a:hover {
      color: map_get($scheme, primary);
    }
  }
}

//--------------------------------------------------------------
//                   Sub menu lvl2 styles
//--------------------------------------------------------------
.sf-menu ul ul {
  position: absolute;
  left: 100%;
  top: 20%;
  width: 205px;
  margin-top: 0;
  padding: 0;
  z-index: 4;


}

//--------------------------------------------------------------
//                 Sub menu lvl2 item styles
//--------------------------------------------------------------
.sf-menu ul ul {
  > li {
    > a {
      color: map_get($scheme, secondary);
      background: map-get($scheme, default);
    }

    &.sfHover > a, > a:hover {
      color: map_get($scheme, primary);
    }
  }
}

//--------------------------------------------------------------
//                      sf-with-ul styles
//--------------------------------------------------------------
.sf-menu > li > .sf-with-ul {
  position: relative;
  margin-right: 17px;

  &:before {
    position: absolute;
    left: 110%;
    top: 15%;
    content: '\f107';
    font: 400 18px/18px $fa;
    display: block; margin-right: 20px;
  }
}
.sf-menu  ul > li >.sf-with-ul {
  position: relative;
  &:before {
    position: absolute;
    right: 0;
    top: 32%;
    content: '\f105';
    font: 400 18px/18px $fa;
    display: block; margin-right: 20px;
  }
}
//--------------------------------------------------------------
//                        Responsive
//--------------------------------------------------------------

@media (max-width: 1199px) {
  .sf-menu ul ul {
    width: 150px;
  }
  .sf-menu ul {
    width: 200px;
  }
}

@media  (max-width: 991px) {
  .sf-menu ul {
    left: -50px;
  }
}
@media (max-width: 767px) {
  .sf-menu {
    display: none;
  }
}
