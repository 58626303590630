/*==================  Stuck menu  ======================*/
$stuck-bg: map-get($scheme, default);
$stuck-bc: #f8f8f8;

.stuck_container {

}

.isStuck {
  background: $stuck-bg;
  z-index: 999;
  border-bottom: 1px solid $stuck-bc;
  .brand_name, .search-form,.sf-menu {
    margin-top: 7px;
  }
  .sf-menu ul {
    margin-top: 25px;
  }
 .brand_slogan { margin-bottom: 2px;}
}

@media only screen and (max-width: 979px) {
  .isStuck {
    display: none !important;
  }
}